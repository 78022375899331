import React, { useState } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import LogoSm from "../images/logo-sm-white.png";
import { Link, useNavigate } from "react-router-dom";
import { postToAPI } from './Utils/utils.jsx'; // Import utility functions
import { toast, ToastContainer } from "react-toastify";

export default function ForgotPassword() {
  const [inputData, setInputData] = useState({
    user_input: "",
  });

  const navigate = useNavigate(); // Initialize useNavigate hook
  const handlePasswordReset = async () => {
    const isEmail = /\S+@\S+\.\S+/.test(inputData.user_input);
    const isMobileWithCountryCode = /^\+\d{1,3}\d{10}$/.test(inputData.user_input); // Updated regex

    if (!isEmail && !isMobileWithCountryCode) {
      toast.error("Please enter a valid email address or a mobile number with country code.",'error');
      return;
    }

    try {
      // Placeholder for API call to initiate password reset
      const response = await postToAPI("generate_otp/", {
        user_input: inputData.user_input,
      });

      if (response.status === true) {
        if (isEmail) {
          toast.error("OTP sent to your email.",'error');
        } else if (isMobileWithCountryCode) {
          toast.error("OTP sent to your mobile number.",'error');
        }
        localStorage.setItem('loginData', JSON.stringify(inputData.user_input));
        const storedData = JSON.parse(localStorage.getItem('loginData'));
        navigate("/otp"); // Redirect to OTP entry page
      } else {
        alert(response.message || "An error occurred."); // Adjusted to use response.message
      }
    } catch (error) {
      toast.error("Email/Mobile Number Not Found:", "error");
    }
  };

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  // Form validation
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      event.preventDefault(); // Prevent page reload
      handlePasswordReset(); // Trigger password reset if form is valid
    }

    setValidated(true); // Set form as validated
  };

  return (
    <>
      <div className="auth-bg py-4 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={8} lg={5} xxl={4}>
              <Card>
                <div className="auth-logo">
                  <img src={LogoSm} alt="default" className="png-img-shadow rounded-circle" />
                </div>
                <Link to="/" className="avatar avatar-md bg-light text-dark rounded mt-2 ms-3">
                  <i class="bi bi-chevron-left fs-22"></i>
                </Link>
                <Card.Body className="pt-4">
                  {/* card text */}
                  <Card.Title className="text-center mt-3 mb-0">Forgot Password?</Card.Title>
                  <p className="text-center text-muted">Enter your email or mobile number for instructions.</p>

                  {/* Form implementation */}
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="form-space" controlId="formBasicInput">
                      <Form.Label>Email Address or Mobile Number With Country Code *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email or mobile number with country code..."
                        name="user_input"
                        onChange={handleChange}
                        value={inputData.user_input}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email, mobile number or country code.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" className="btn-primary w-100">Submit</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
