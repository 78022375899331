import React, { useState, useEffect } from "react";
import { Card, Button, Table, Form, Modal, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from "../Utils/utils";
import { toast } from "react-toastify";


export default function ComplaintTypes({ complaintData }) {
    // Modal
    const [showComplaintModal, setShowComplaintModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const Static_Category = "Garbage Collection"; // This is static to show types (Will be removed in future)
    const [complaintTypesData, setComplaintTypesData] = useState();

    // Form validation
    const [validated, setValidated] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [EditCatID, setEditCatID] = useState();
    const [DeleteCatID, setDeleteCatID] = useState();
    const [isDuplicateName, setIsDuplicateName] = useState(false);

    const InitialCatInputdata = {
        name: "",
        description: "N/A",
    };

    const [CatInputData, setCatInputData ] = useState(InitialCatInputdata);

    const FetchAllComplaintType = () => {
        getFromAPI('category/').then(data => {
            setComplaintTypesData(data);
        }).catch(error => {
            console.error('Error fetching complaints:', error);
        });
    }

    const handleNameChange = (e) => {
        let name_val = e.target.value;
        setCatInputData({ ...CatInputData, name: name_val });
        const nameExists = complaintTypesData.some(cat => cat.name === name_val);
        setIsDuplicateName(nameExists);
    }

    const handleAddCategory = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault();
            setValidated(true);
            if(CatInputData.name.trim() === "") {
                toast.error('Category name cannot be empty');
                return;
            }
            if(isEdit) {
                try {
                    const Response = await putToAPI(`category/${EditCatID}/`, CatInputData);
                    if (Response.status) {
                        toast.success('Category Successfully updated');
                        UpdateCatDataTable();
                        handleCloseComplaintModal();
                    } else {
                        toast.error('Failed to update Category');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                if (isDuplicateName) {
                    toast.error('Complaint types already exists');
                    return;
                }
                try {
                    const Response = await postToAPI("category/", CatInputData);
                    if (Response.status) {
                        toast.success('Category Added Successfully');
                        UpdateCatDataTable();
                        handleCloseComplaintModal();
                    } else {
                        toast.error('Failed to add Category');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    const handleCloseComplaintModal = () => {
        setShowComplaintModal(false);
        setIsEdit(false);
        setValidated(false);
        setEditCatID(null);
        setCatInputData(InitialCatInputdata);
    }

    const handleEditCategory = (title, complaint_id) => {
        setIsEdit(true);
        setEditCatID(complaint_id);
        getFromAPI(`category/?id=${complaint_id}`)
            .then(dataArr => {
                let Cat_data = dataArr[0];
                setCatInputData({
                    name: Cat_data.name || '',
                    description: Cat_data.description || ''
                });
                handleOpenComplaintModal(title);
            })
    };    
    
    const handleOpenComplaintModal = (title) => {
        setModalTitle(title);
        setShowComplaintModal(true);
    };

    const handleDeleteData = (id) => {
        setDeleteCatID(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteCatID(null);
        setShowDeleteModal(false);
    }

    const handleCatDelete = async () => {
        try {
            const Response = await deleteFromAPI(`category/${DeleteCatID}/`);
            if (Response.status) {
                toast.success('Category Successfully deleted');
                UpdateCatDataTable();
                handleCloseDeleteModal();
            } else {
                toast.error('Failed to delete Category');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }
    }

    // Datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (complaintTypesData && !$.fn.dataTable.isDataTable("#complaintDataTable")) {
            $("#complaintDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)',
                        },
                        customize: function (doc) {
                            // Set table layout to make the borders visible
                            doc.content[1].table.body.forEach(function(row) {
                                row.forEach(function(cell) {
                                    cell.border = [true, true, true, true]; // Top, left, bottom, right
                                });
                            });
                            doc.content[1].layout = {
                                hLineWidth: function (i, node) {
                                    return 1;
                                },
                                vLineWidth: function (i, node) {
                                    return 1;
                                },
                                hLineColor: function (i, node) {
                                    return '#000000'; // Border color for horizontal lines
                                },
                                vLineColor: function (i, node) {
                                    return '#000000'; // Border color for vertical lines
                                },
                                paddingLeft: function (i, node) { return 4; }, // Padding inside the cells
                                paddingRight: function (i, node) { return 4; },
                                paddingTop: function (i, node) { return 4; },
                                paddingBottom: function (i, node) { return 4; }
                            };

                            doc.styles.tableHeader.fontSize = 10;
                            doc.defaultStyle.fontSize = 10;
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: complaintTypesData.map((data, i) => [
                    i + 1,
                    data.name,
                    data.description,
                    Static_Category,
                    data.id,
                ]),
                columns: [
                    { title: "No." },
                    { title: "Complaint Type" },
                    { title: "Description" },
                    { title: "Category" },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-complaint-type"  data-ct-id="${row[4]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-complaint-type" data-ct-id="${row[4]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                select: true,
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#complaintDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-complaint-type')) {
                    const complaint_id = event.target.getAttribute('data-ct-id');
                    handleEditCategory("Edit Complaint Types", complaint_id);
                }
                if (event.target && event.target.classList.contains('delete-complaint-type')) {
                    const complaint_id = event.target.getAttribute('data-ct-id');
                    handleDeleteData(complaint_id);
                }
            });
        }
    }, [complaintTypesData]);

    const UpdateCatDataTable = () => {
        const PODT = $('#complaintDataTable').DataTable();
        getFromAPI("category/")
            .then(response => {
                setComplaintTypesData(response);
                let DTData = response.map((data, i) => [
                    i + 1,
                    data.name,
                    data.description,
                    Static_Category,
                    data.id,
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(()=>{
        FetchAllComplaintType();
    }, []);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Complaint Types</Card.Title>
                <Button variant="primary" onClick={() => handleOpenComplaintModal("Add Complaint Type")}>
                    <i className="bi bi-plus-square me-2"></i> Add Complaint Type
                </Button>
            </div>

            <Table responsive bordered id="complaintDataTable">
                <thead className="table-light"></thead>
            </Table>

            {/* Complaint Modal */}
            <Modal centered size="lg" show={showComplaintModal} onHide={handleCloseComplaintModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddCategory(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Complaint Type</Form.Label>
                                    <Form.Control type="text" name="type" placeholder="Enter complaint types..." value={CatInputData.name} onChange={(e) => handleNameChange(e) } required isInvalid={validated && isDuplicateName} />
                                    <Form.Control.Feedback type="invalid">
                                      {isDuplicateName ? "Complaint type already exists." : "Please enter complaint type."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter description..." name="description" value={CatInputData.description} onChange={(e) => setCatInputData({...CatInputData, description:e.target.value})} required isInvalid={validated && !CatInputData.description} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter description or NA.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button type="submit" variant="primary">Save</Button>
                        <Button variant="light" onClick={handleCloseComplaintModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleCatDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
