import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { postToAPI,getFromAPI } from '../Utils/utils.jsx'; // Import utility functions

// img
import Logo from '../../images/logo.png';
// import LogoSm from '../../images/logo-sm.png';

export default function TopMenu() {
    const storeduserid = localStorage.getItem('userId');
    const [userName, setUserName] = useState('');
  
    useEffect(() => {
      const fetchData = async () => {
        // Retrieve userType from localStorage
        const userType = localStorage.getItem('userType');
  
        // Ensure userType is a string for comparison
        if (userType === "1") {
          try {
            const storeduserid = localStorage.getItem('userId'); // Retrieve stored userId
            const fetchCitizenData = await getFromAPI(`Citizen/?id=${storeduserid}`);
            const fullName = fetchCitizenData[0].FirstName+" "+fetchCitizenData[0].LastName;
          } catch (error) {
            console.error('Error fetching citizen data:', error);
          }
        } else if (userType === "2") {
          try {
            const storeduserid = localStorage.getItem('userId'); // Retrieve stored userId
            const fetchSupervisorData = await getFromAPI(`Supervisor/?employeeId=${storeduserid}`);
            const fullName = fetchSupervisorData[0].FirstName+" "+fetchSupervisorData[0].LastName;
            setUserName(fullName);
          } catch (error) {
            console.error('Error fetching supervisor data:', error);
          }
        }
      };
  
      fetchData(); // Call the async function
    }, []); // Empty dependency array means this effect runs once on mount

    const location = useLocation();
    return (
        <>
            <Navbar expand="lg" className="top-menu">
                <Container>
                    <div className='leftbar-logo'>
                        <Link to='/dashboard' >
                            <img src={Logo} alt="" className='' height={32} />
                            {/* <img src={Logo} alt="" className='d-sm-block d-none' height={34} /> */}
                            {/* <img src={LogoSm} alt="" className='d-sm-none d-block' height={34} /> */}
                        </Link>
                    </div>
                    <div className='topbar-user d-block d-lg-none ms-auto'>
                        <Dropdown>
                            <Dropdown.Toggle variant="p-0" id="top-user-sm">
                                <div className='d-flex align-items-center'>
                                    <i className="bi bi-person-fill fs-26"></i>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" title="Dropdown end">
                                <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Navbar.Toggle className='ms-3' aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Link to='/dashboard' className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>Dashboard</Link>
                            <Link to='/master' className={`nav-link ${location.pathname === '/master' ? 'active' : ''}`}>Master</Link>
                        </Nav>
                        <div className='topbar-user d-none d-lg-block'>
                            <Dropdown>
                                <Dropdown.Toggle variant="p-0" id="top-user-lg">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-person-fill fs-26"></i> <h5 className='fs-20 ms-2 mb-0'>{userName}</h5>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" title="Dropdown end">
                                    <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
