import React, { useState, useEffect } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import LogoSm from "../images/logo-sm-white.png";
import { Link, useNavigate } from "react-router-dom";
import { postToAPI } from './Utils/utils.jsx'; // Import utility functions
import { toast, ToastContainer } from "react-toastify";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [inputData, setInputData] = useState({
    emailOrMobile: "",
    password: "",
    userType : 2,
  });
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  // Check if email and password are saved in localStorage
  useEffect(() => {
    const savedEmail = localStorage.getItem("emailOrMobile");
    const savedPassword = localStorage.getItem("password");

    if (savedEmail && savedPassword) {
      setInputData({
        emailOrMobile: savedEmail,
        password: savedPassword,
        userType:2,
      });
      setRememberMe(true); // Check the checkbox if credentials were saved
    }
  }, []);

  const isValidEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isValidMobile = (mobileNo) => {
    const re = /^\d{10}$/;
    return re.test(mobileNo);
  };

  const handleLogin = async () => {
    try {
      const isEmail = /\S+@\S+\.\S+/.test(inputData.emailOrMobile);
      const isMobileWithCountryCode = /^\+\d{1,3}\d{10}$/.test(inputData.emailOrMobile);

      if (!isEmail && !isMobileWithCountryCode) {
        toast.error("Please enter a valid Email address or a Mobile Number with Country code.", 'error');
        return;
      }

      const response = await postToAPI("login/", {
        emailOrMobile: inputData.emailOrMobile,
        password: inputData.password,
        userType:2
      });

      if (response.status === true) {
        localStorage.setItem('userType', response.data.userType);
        localStorage.setItem('userId', response.data.userId);

        // Save email and password if "Remember me" is checked
        if (rememberMe) {
          localStorage.setItem("emailOrMobile", inputData.emailOrMobile);
          localStorage.setItem("password", inputData.password);
        } else {
          localStorage.removeItem("emailOrMobile");
          localStorage.removeItem("password");
        }

        navigate("/dashboard");
      } else {
        toast.error("Invalid email or password", 'error');
      }
    } catch (error) {
      toast.error("Invalid Email/Mobile Number or password", 'error');
    }
  };

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  // Form validation
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault(); 
      handleLogin();
    }
    setValidated(true);
  };

  return (
    <>
      <div className="auth-bg py-4 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={8} lg={5} xxl={4}>
              <Card>
                <div className="auth-logo">
                  <img src={LogoSm} alt="default" className="png-img-shadow rounded-circle" />
                </div>
                <Card.Body className="pt-5 mt-4">
                  <Card.Title className="text-center mt-3 mb-0">Welcome Back!</Card.Title>
                  <p className="text-center text-muted">Please enter your details to login.</p>

                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="form-space" controlId="formBasicEmailOrMobile">
                      <Form.Label>Email Address or Mobile Number With Country Code <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter email or mobile number with country code..."
                        name="emailOrMobile"
                        value={inputData.emailOrMobile}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid email or mobile number with country code.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-space" controlId="formBasicPassword">
                      <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                      <div className="input-icon-content">
                        <i
                          className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"} input-icon`}
                          onMouseDown={() => setShowPassword(true)}
                          onMouseUp={() => setShowPassword(false)}
                          onMouseOut={() => setShowPassword(false)}
                          onTouchStart={() => setShowPassword(true)}
                          onTouchEnd={() => setShowPassword(false)}
                        ></i>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password..."
                          name="password"
                          value={inputData.password}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid password.
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="d-flex align-items-center justify-content-between form-space">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          className="pointer-cursor"
                          checked={rememberMe}
                          onChange={() => setRememberMe(!rememberMe)}
                        />
                      </Form.Group>
                      <Link className="text-muted" to="/forgot">Forgot Password?</Link>
                    </div>

                    <Button type="submit" className="btn-primary w-100">
                      <i className="bi bi-box-arrow-in-right me-2"></i>
                      Login
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
