import React, { useState, useEffect } from "react";
import { Card, Button, Table, Form, Modal, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast } from "react-toastify";

export default function Zones() {
    // Modal
    const [showZoneModal, setShowZoneModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [zoneData, setzoneData] = useState();
    const [wardData, setwardData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [EditZoneID, setEditZoneID] = useState();
    const [DeleteZoneID, setDeleteZoneID] = useState();
    const [isDuplicateName, setIsDuplicateName] = useState(false);
    const [isDuplicateCode, setIsDuplicateCode] = useState(false);

    // Form validation
    const [validated, setValidated] = useState(false);

    const [zoneInputData, setzoneInputData] = useState({
        name: "",
        code: "",   
    })

    const FetchZoneData = () => {
        getFromAPI('zone/').then(data => {
            setzoneData(data);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const FetchWardData = () => {
        getFromAPI('ward/').then(data => {
            setwardData(data.ward);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const handleNameChange = (e) => {
        let name_val = e.target.value.trim();
        setzoneInputData({ ...zoneInputData, name: name_val });
        const nameExists = zoneData.some(zone => zone.name === name_val);
        setIsDuplicateName(nameExists);
    }

    const handleCodeChange = (e) => {
        let code_val = e.target.value.trim();
        setzoneInputData({ ...zoneInputData, code: code_val });
        const codeExists = zoneData.some(zone => zone.code === code_val);   
        setIsDuplicateCode(codeExists);
    }

    const handleAddZone = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);
            if(isEdit) {
                try {
                    const Response = await putToAPI(`zone/${EditZoneID}/`, zoneInputData);
                    if (Response.status) {
                        toast.success('Zone Successfully updated');
                        UpdateZoneDataTable();
                        handleCloseZoneModal();
                    } else {
                        toast.error('Failed to update Zone');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                if (isDuplicateName) {
                    toast.error('Zone name already exists', 'error');
                    return;
                }
                if (isDuplicateCode) {
                    toast.error('Zone code already exists', 'error');
                    return;
                }
                try {
                    const Response = await postToAPI("zone/", zoneInputData);
                    if (Response.status) {
                        toast.success('Zone Added Successfully');
                        UpdateZoneDataTable();
                        handleCloseZoneModal();
                    } else {
                        toast.error('Failed to add Zone');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    const HandleEditZone = (title, zone_id) => {
        setIsEdit(true);
        setEditZoneID(zone_id);
        getFromAPI(`zone/?id=${zone_id}`).then(dataArr => {
            let zone_data = dataArr[0];
            setzoneInputData({ name: zone_data.name || '', code: zone_data.code || '' });
            handleOpenZoneModal(title);
        }).catch(error => {
            console.error('Error fetching zone:', error);
        });
    };
    
    const handleOpenZoneModal = (title) => {
        setModalTitle(title);
        setShowZoneModal(true);
        // TODO: set supervisor data from zone_id if isEdit is true 
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteZoneID(id);
        setShowDeleteModal(true);
    };

    const handleDeleteZone = async() => {
        let hasAssignedWard = wardData.some((ward) => parseInt(ward.zone.id) === parseInt(DeleteZoneID));
        if(hasAssignedWard) {
            toast.error('This zone has wards assigned to it. Please remove or reassign these wards before attempting to delete the zone.');
        } else {
            try {
                const Response = await deleteFromAPI(`zone/${DeleteZoneID}/`);
                if (Response.status) {
                    toast.success('Zone Successfully deleted');
                    UpdateZoneDataTable();
                    handleCloseDeleteModal();
                } else {
                    toast.error('Failed to delete Zone');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An unexpected error occurred');
            }
        }
    }

    const handleCloseDeleteModal = () => {
        setDeleteZoneID(null);
        setShowDeleteModal(false);
    }

    const handleCloseZoneModal = () => {
        setIsEdit(false);
        setValidated(false);
        setShowZoneModal(false);
        setEditZoneID(null);
        setzoneInputData({
            name: "",
            code: "", 
        });
    }

    // Datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (zoneData && wardData && !$.fn.dataTable.isDataTable("#zoneDataTable")) {
            $("#zoneDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        },
                        customize: function (doc) {
                            // Set table layout to make the borders visible
                            doc.content[1].table.body.forEach(function(row) {
                                row.forEach(function(cell) {
                                    cell.border = [true, true, true, true]; // Top, left, bottom, right
                                });
                            });
                            doc.content[1].layout = {
                                hLineWidth: function (i, node) {
                                    return 1;
                                },
                                vLineWidth: function (i, node) {
                                    return 1;
                                },
                                hLineColor: function (i, node) {
                                    return '#000000'; // Border color for horizontal lines
                                },
                                vLineColor: function (i, node) {
                                    return '#000000'; // Border color for vertical lines
                                },
                                paddingLeft: function (i, node) { return 4; }, // Padding inside the cells
                                paddingRight: function (i, node) { return 4; },
                                paddingTop: function (i, node) { return 4; },
                                paddingBottom: function (i, node) { return 4; }
                            };

                            doc.styles.tableHeader.fontSize = 10;
                            doc.defaultStyle.fontSize = 10;
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: zoneData.map((data, i) => [
                    i + 1,
                    data.code,
                    data.name,
                    data.id
                ]),
                columns: [
                    { title: "No." },
                    { title: "Zone Code" },
                    { title: "Zone Name" },
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-zone" data-zone-id="${row[3]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-zone" data-zone-id="${row[3]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                select: true,
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#zoneDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-zone')) {
                    const zone_id = event.target.getAttribute('data-zone-id');
                    HandleEditZone("Edit Zone", zone_id);
                }
                if (event.target && event.target.classList.contains('delete-zone')) {
                    const zone_id = event.target.getAttribute('data-zone-id');
                    handleDeleteData(zone_id);
                }
            });

            // Ward filter logic
            $('#wardFilter').on('change', function () {
                const selectedWardID = $(this).val();
                const selectedWardZone = wardData.find((ward) => {return parseInt(ward.id) === parseInt(selectedWardID);});
                if(selectedWardZone) $("#zoneDataTable").DataTable().column(2).search(selectedWardZone.zone.name).draw();
                else $("#zoneDataTable").DataTable().column(2).search("").draw();
            });
        }
    }, [zoneData, wardData]);

    const UpdateZoneDataTable = () => {
        const PODT = $('#zoneDataTable').DataTable();
        getFromAPI("zone/")
            .then(response => {
                setzoneData(response);
                let DTData = response.map((data, i) => [
                    i + 1,
                    data.code,
                    data.name,
                    data.id
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(() => {
        FetchZoneData();
        FetchWardData();
    }, [])

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Zones</Card.Title>
                <Button variant="primary" onClick={() => handleOpenZoneModal("Add Zone")}>
                    <i className="bi bi-plus-square me-2"></i> Add Zone
                </Button>
            </div>

            <div className="d-inline-flex gap-2 mb-3">
    
                {/* Ward Filter */}
                <Form.Select id="wardFilter" aria-label="Default select example" size="md">
                    <option>Select Ward</option>
                    {wardData && wardData.map((ward) => (
                        <option key={ward.id} value={ward.id}>
                            {ward.name}
                        </option>
                    ))}
                </Form.Select>
            </div>

            <Table responsive bordered id="zoneDataTable">
                <thead className="table-light"></thead>
            </Table>

            {/* Zone modal */}
            <Modal centered size="lg" show={showZoneModal} onHide={handleCloseZoneModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddZone(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Zone Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter zone name..." name="name" value={zoneInputData.name} onChange={(e)=> handleNameChange(e) } required isInvalid={validated && isDuplicateName} />
                                    <Form.Control.Feedback type="invalid">
                                      { isDuplicateName ? "Zone name already exists." : "Please enter zone name."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Zone Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter zone name..." name="name" value={zoneInputData.code} onChange={(e)=> handleCodeChange(e) } required isInvalid={validated && isDuplicateCode}/>

                                    <Form.Control.Feedback type="invalid">
                                      { isDuplicateCode ? "Zone code already exists." : "Please enter zone code."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button type="submit" variant="primary">Save</Button>
                        <Button variant="light" onClick={handleCloseZoneModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleDeleteZone}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
